import { showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog";
import { base64toBlob } from "@qogni-technologies/design-system/src/shared/common";
import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { calculateAge } from "../shared/common";
import { MasterDataDoamin } from "./master-data-domain";

export class AccountDomain extends AppDomainHandler {
  #api;
  static #instance;

  constructor() {
    super();

    if (AccountDomain.#instance) return AccountDomain.#instance; // singleton
    AccountDomain.#instance = this;

    this.#api = ApiRequest.factory();

    window.AccountDomain = this;
  }

  /**
   * Gets the singleton instance of the AccountDomain class.
   */
  static get singleton() {
    if (!this.#instance) new this();
    return this.#instance;
  }

  async getJobList() {
    const lang = app.session.user?.language ?? "en-US";
    let langCode = "en";
    if (lang === "nl-NL") langCode = "nl";

    const response = await fetch(`/assets/data/${langCode}/jobs.json`);
    return await response.json();
  }

  /**
   * Generate email request code by given email.
   *
   * @param email
   * @returns {Promise<string|false>}
   */
  async updateEmailRequest(email) {
    let result;
    try {
      result = await this.#api.postData("/users/me/email/request-code", {
        new_email_address: email,
      });
    } catch (err) {
      if (err.response && err.response.status === 409) {
        throw new Error("The email address given is already in use");
      }
      throw err;
    }
    if (!result.status) {
      return false;
    }

    return result.data.change_jwt;
  }

  async getEmails(force = false) {
    const result = await this.#api.getData("/users/me/emails", {
      ...(!force && {cache: {
        duration: 5 * 60 * 1000, // 5 mins
      },})
    });

    if (!result.status) return false;
    return result.data;
  }

  getHealthDetails() {
    const user = app.session.user;

    const date = new Date(Date.parse(user.date_of_birth));

    return {
      fullName: `${user.firstname} ${user.lastname}`,
      age: isNaN(date) ? "unknown" : calculateAge(date),
      diet: this.getFoodPreference(),
      allergies: this.getAllergiesAndIntolerances(),
      topics: user.interest_topics.map((t) => t.name),
      intention: user.health_intention,
    };
  }

  // get current food pref
  getFoodPreference() {
    return app.session.user?.food_preference?.name;
  }

  /**
   * Updates a user email in the application
   *
   * @param {String} email
   * @param {Object} options - { primary: boolean, work: boolean }
   */
  async updateEmail(email, options) {
    let result;

    try {
      result = await this.#api.putData(`/users/me/emails/${email}`, {
        primaty: false,
        work: false,
        ...(options && { ...options }),
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Entered Email already verified", {
            type: "error",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async emailVerificationRequestCode(email) {
    let result;
    try {
      result = await this.#api.postData(
        `/users/me/emails/${email}/verification/request-code`
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Entered Email already verified", {
            type: "error",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data.verify_jwt;
  }

  async emailVerificationComplete(email, verifyJwt, pin) {
    let result;
    try {
      result = await this.#api.postData(
        `/users/me/emails/${email}/verification/complete`,
        {
          pin,
          verify_jwt: verifyJwt,
        }
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 403:
          app.addToastMessage("Entered pin is incorrect or expired!", {
            type: "error",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.status;
  }

  async addNewEmail(email) {
    let result;

    try {
      result = await this.#api.postData("/users/me/emails", {
        email: email,
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 403:
          app.addToastMessage(
            "We allow a maximum of 10 email addresses per account",
            { type: "error" }
          );
          return false;
        case 409:
          await showAlert({
            title: "Alert!",
            message: "The email address given is already in use",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;

    app.addToastMessage("New Email added");
    return result.data;
  }

  async deleteEmail(email) {
    let result;
    try {
      result = await this.#api.deleteData(`/users/me/emails/${email}`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 404:
          app.addToastMessage("Emails not found", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Cannot remove primary email", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.status;
  }

  /**
   * Ask for confirmation code to be sent in order to delete the account.
   *
   * @returns {Promise<string|false>}
   */
  async deleteRequest() {
    const result = await this.#api.postData("/users/me/delete/request-code");
    if (!result.status) {
      return false;
    }

    return result.data.delete_jwt;
  }

  /**
   * Confirm account deletion.
   *
   * @returns {Promise<boolean>}
   * @param deleteJwt
   * @param pin
   * @param reason
   * @param reasonExtra
   */
  async deleteConfirm(deleteJwt, pin, reason, reasonExtra) {
    let result;

    try {
      result = await this.#api.postData("/users/me/delete/confirm", {
        delete_jwt: deleteJwt,
        pin,
        reason,
        reason_extra: reasonExtra,
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 403:
          app.addToastMessage("Entered PIN is incorrect or expired!", {
            type: "error",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  /**
   * Update profile fields.
   * @param details
   * @returns {Promise<*>}
   */
  async updateProfile(details) {
    await app.session.setUser({
      ...app.session.user,
      ...details,
    });

    const result = await this.#api.putData("/users/me", {
      ...details,
    });
    return result.status;
  }

  async setSingleProperty(key, value) {
    let changes = false;

    const userProperties = {};
    switch (key) {
      case "topics": {
        const topicResults = await this.getAllPossibleTopics();
        userProperties["interest_topics"] = topicResults.data
          .filter((x) => value.includes(x.name))
          .map((x) => x.id);
        break;
      }

      case "hasAllergies":
        if (value === false) {
          this.removeAllAllergiesAndIntolerances(userProperties, value);
        }
        break;
      case "celiacDisease":
        { 
          const masterAllergens = MasterDataDoamin.singleton.allergens;
          const celiacId = masterAllergens.find(ms => ms.name.includes('Celiac'))?.id;
          let currentAllergens = app.session.user.allergens;

          if (value === true) {
            userProperties['allergens'] = [...currentAllergens, celiacId]
          } else {
            userProperties['allergens'] = currentAllergens.filter(i => i !== celiacId);
          }
          break; 
        }
      default:
        userProperties[key] = value;
    }

    try {
      // await app.session.setUser({
      //   ...app.session.user,
      //   ...userProperties,
      // });

      changes = true;
    } catch (err) {
      console.warn("setSingleProperty error", err);
    } finally {
      if (changes) {
        requestAnimationFrame(() => {
          this.updateProfile(userProperties);
        });
      }
    }
  }

  getDietName() {
    const foodPreferenceId = app.session.user.food_preference_id;
    if (!foodPreferenceId) return;
    const foodPreferences = MasterDataDoamin.singleton.foodPreferences;
    const singleFp = foodPreferences.find(e => e.id === foodPreferenceId);
    if (!singleFp) return;
    const currentLanguage = app.session.user?.language;

    return singleFp?.translations.find(i => i.locale === currentLanguage)?.value ?? singleFp?.name;
  }

  getAllergiesAndIntolerances() {
    const userAllergens = app.session.user.allergens;
    const userAllergensIds = userAllergens.map(i => i.id);
    const allergens = MasterDataDoamin.singleton.allergens;
    const currentLanguage = app.session.user?.language;

    const filteredAllergens = allergens.filter(e => userAllergensIds.includes(e.id));
    
    const computedNames = filteredAllergens.map((e) => {
      return e?.translations.find(i => i.locale === currentLanguage)?.value ?? e.name
    })

    return computedNames;
  }

  getAllergies() {
    return AccountDomain.allergyList(app.session.user).map(
      (x) => x.alias ?? x.name
    );
  }

  removeAllAllergiesAndIntolerances(userProperties) {
    userProperties['allergens'] = [];
  }

  async getSingleProperty(key) {
    return app.session.user[key] ?? null;
  }

  async uploadProfileImage(imageBase64, contentType) {
    const formData = new FormData();
    formData.append(
      "file",
      base64toBlob(imageBase64, contentType),
      "profile-picture.jpg"
    );
    await this.#api.uploadFile("POST", "/users/me/picture", formData);
  }

  /**
   * Delete current profile picture.
   * @returns {Promise<*>}
   */
  async deleteProfilePicture() {
    return this.updateProfile({
      profile_img: null,
    });
  }

  /**
   * Get all possible topics.
   *
   * @returns {Promise<*|string>}
   */
  async getAllPossibleTopics() {
    return await this.#api.getData("/interest_topics", {
      cache: {
        duration: 60 * 60 * 1000, // 1 hour
      },
    });
  }

  async getConnections(options = {}) {
    return await this.#api.getData(
      `/users/me/connections?${new URLSearchParams(options ?? {})}`
    );
  }

  async followUser(userId) {
    return await this.#api.postData(`/users/${userId}/follow`);
  }

  async unfollowUser(userId) {
    return await this.#api.postData(`/users/${userId}/unfollow`);
  }

  async searchUsers(options = {}) {
    return await this.#api.getData(
      `/users?${new URLSearchParams(options?.query ?? {})}`,
      options
    );
  }

  async getUser(selector, options = {}) {
    return await this.#api.getData(
      `/users/${selector}?${new URLSearchParams(options?.query ?? {})}`,
      options
    );
  }

  async getUserPosts(selector, options = {}) {
    return await this.#api.getData(
      `/users/${selector}/posts?${new URLSearchParams(options?.query ?? {})}`,
      options
    );
  }

  async poll() {
    return await this.#api.getData(`/users/me/polling`, {
      abortPrevious: 'me-polling',
    });
  }

  async createInvitation(source) {
    const response = await this.#api.postData(`/invitations`, { source });
    return response.data.id;
  }

  async createAdvancedInvitation(data) {
    const response = await this.#api.postData(`/invitations`, data);
    return response.data.id;
  }

  async suggestedUsers() {
    const response = await this.#api.getData(
      `/users/me/connections/suggested`,
      {}
    );
    return {
      suggestions: this.#mergeArraysToMax(
        response.data.suggested,
        response.data.random,
        3
      ),
      experts: response.data.experts,
    };
  }

  #mergeArraysToMax(arr1, arr2, max = 3) {
    const result = [];
    const length1 = arr1.length;
    const length2 = arr2.length;

    // Ensure we have at least one item from the second array
    let itemsFromArr2 = Math.min(length2, 1);
    let itemsFromArr1 = Math.min(length1, max - itemsFromArr2);

    // Adjust if the first array has fewer items than needed
    if (itemsFromArr1 + itemsFromArr2 < max && itemsFromArr2 < length2) {
      itemsFromArr2 = Math.min(length2, max - itemsFromArr1);
    }

    // Add elements from the first array
    for (let i = 0; i < itemsFromArr1; i++) {
      result.push(arr1[i]);
    }

    // Add elements from the second array
    for (let i = 0; i < itemsFromArr2; i++) {
      result.push(arr2[i]);
    }

    return result;
  }

  async getFollowing(page = 1) {
    const query = {
      page,
      per_page: 25,
    };

    return await this.#api.getData(
      `/users/me/following?${new URLSearchParams(query)}`
    );
  }

  async getFollowers(page = 1) {
    const query = {
      page,
      per_page: 25,
    };

    return await this.#api.getData(
      `/users/me/followers?${new URLSearchParams(query)}`
    );
  }

  async getSavedLists() {
    return await this.#api.getData("/users/me/saved_lists");
  }

  async saveItemToSavedList(options) {
    let result;
    let listId;

    try {
      if (app.cache.getValue("saved_list")) {
        listId = app.cache.getValue("saved_list")[0].id;
      } else {
        const res = await this.getSavedLists();
        app.cache.setValue("saved_list", res.data);
        listId = res.data[0].id;
      }
      result = await this.#api.postData(
        `/users/me/saved_lists/${listId}/saved_items`,
        options
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 404:
          app.addToastMessage("List not found", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Item already saved before", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async unsaveItemToSavedList(itemId) {
    let result;
    let listId;

    try {
      if (app.cache.getValue("saved_list")) {
        listId = app.cache.getValue("saved_list")[0].id;
      } else {
        const res = await this.getSavedLists();
        app.cache.setValue("saved_list", res.data);
        listId = res.data[0].id;
      }
      result = await this.#api.deleteData(
        `/users/me/saved_lists/${listId}/saved_items/${itemId}`
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 404:
          app.addToastMessage("Entity not found", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async getSavedListItems(listId, options = {}) {
    const query = {
      page: 1,
      per_page: 15,
      ...options,
    };

    return await this.#api.getData(
      `/users/me/saved_lists/${listId}/saved_items?${new URLSearchParams(
        query
      )}`
    );
  }
}
