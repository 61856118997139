import { html, nothing } from "lit";
import { ResultsBase } from "./results-base";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { repeat } from "lit/directives/repeat.js";
import { Converter } from "showdown";
import { msg, str } from '@lit/localize';

export class PageTopics extends ResultsBase {
  constructor() {
    super();
    this.title = msg("Health topics");
  }

  renderContent() {
    const hc = this.results?.healthcheck;

    return html`
      <div class="${!hc ? "preview-wrap" : ""}">
        ${!hc || !hc.totals
          ? html`
              <div class="preview-content">
                <badge-tag class="mb-tiny blue small">
                  ${msg("HealthCheck Results")}
                </badge-tag>

                <h1>${msg("HealthCheck not finished!", { desc: "Header text indicating that the HealthCheck is incomplete." })}</h1>
                <p>
                  ${msg("You haven't completed the HealthCheck before. In order to see your personalized health status, head over to the HealthCheck and complete it!", { desc: "Prompt to complete the HealthCheck to view personalized health status." })}
                </p>

                <flex-container class="justify-content-center">
                  <flex-item>
                    <a href="/healthcheck" class="button green wide">
                      ${msg("Start HealthCheck")}
                    </a>
                  </flex-item>
                </flex-container>
              </div>
            `
          : nothing}
        ${this.renderHealthCheckStats(hc)}
      </div>
    `;
  }

  renderHealthCheckStats(hc) {
    if (!hc || !hc.totals) return this.renderHealthCheckDefaults();
    const colors = ["red", "yellow", "green", "blue"];
    const sortedTotals = hc.totals.sort((a, b) => b.normalised - a.normalised);

    return html`
      <section class="card dark-green">
        <h2>${msg("HealthCheck")}</h2>
        <p>
          ${msg("Based on the answers to the HealthCheck, the below topics are most relevant to you. Based on these outcomes, Qogni will offer customised content.", { desc: "Explains in results page that topics and content are personalized based on the user's HealthCheck responses." })}
        </p>

        ${repeat(sortedTotals, (res, idx) => {
          const color = colors[idx % colors.length];
          return html`
            <section class="card">
              <details class="simple nested">
                <summary>
                  <progress-indicator
                    theme="${color}"
                    label="${res.question_category.name}"
                    value="${res.total}"
                    max="${res.out_of}"
                    hide-out-of
                    type="line"
                  ></progress-indicator>
                  <svg-icon icon="caret"></svg-icon>
                </summary>
                <!-- Content -->
                <section>
                  ${res.question_category?.description
                    ? html`
                        <translatable-content current-language="en">
                          ${unsafeHTML(
                            new Converter().makeHtml(
                              res.question_category.description
                            )
                          )}
                        </translatable-content>
                      `
                    : nothing}
                  ${res.questions && res.questions?.length > 0
                    ? html`
                        <strong>
                          ${msg(str`Questions connected to ${res.question_category.name}:`, { desc: "Displayed as a heading for a list of questions related to a specific category, e.g., 'Questions connected to Vitamins / Hypothyroidism / Cortisol high / Zinc:'." })}
                        </strong>
                        <ul class="mt-none">
                          ${repeat(res.questions, (q) => html` <li>${q}</li> `)}
                        </ul>
                      `
                    : nothing}
                </section>
              </details>
            </section>
          `;
        })}
      </section>
      ${this.renderProgram()}
    `;
  }

  renderHealthCheckDefaults() {
    return html`
      <section class="card dark-green">
        <h2>${msg("HealthCheck")}</h2>
        <p>
          ${msg("Based on the answers to the HealthCheck, the below topics are most relevant to you. Based on these outcomes, Qogni will offer customised content.", { desc: "Explains in results page that topics and content are personalized based on the user's HealthCheck responses." })}
        </p>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>
      </section>
    `;
  }
}
