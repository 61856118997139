// indexeddb-handler.js
export class IndexedDBHandler {
  constructor(dbName = "app-database", storeName = "master-data", version = 1) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.version = version;
    this.db = null;
  }

  async open() {
    return new Promise((resolve, reject) => {
      const dbRequest = indexedDB.open(this.dbName, this.version);

      dbRequest.onsuccess = (event) => {
        this.db = event.target.result;
        return resolve(this);
      };
      dbRequest.onerror = (event) => {
        return reject(event);
      };

      dbRequest.onupgradeneeded = (event) => {
        this.db = event.target.result;
        this.#upgrade(event.target.result, event);
      };
    });
  }

  async #upgrade(db, event) {
    if (event.oldVersion === 0) {
      db.createObjectStore(this.storeName, {
        keyPath: "key",
        autoIncrement: false,
      });
    }
  }

  /**
   * Sets the data in the object store with the specified key.
   * If a value is provided, it updates the existing data with the new value.
   * If no value is provided, it deletes the data with the specified key.
   *
   * @param {string} key - The key of the data to be set.
   * @param {any} value - The new value for the data (optional).
   * @returns {Promise} - A Promise that resolves if the operation is successful, and rejects with an error if it fails.
   */
  async setData(key, value) {
    const objectStore = this.db
      .transaction([this.storeName], "readwrite")
      .objectStore(this.storeName);

    let request;
    if (value) {
      request = objectStore.get(key);
    } else {
      request = objectStore.delete(key);
    }

    return new Promise((resolve, reject) => {
      request.onerror = (event) => reject(event);
      request.onsuccess = (event) => {
        // If it was a delete operation, return resolve.
        if (!value) {
          return resolve();
        }

        // Update.
        if (event.target.result) {
          const data = event.target.result;
          data.value = value;
          const request = objectStore.put(data);

          request.onerror = reject;
          request.onsuccess = resolve;
        } else {
          // Insert.
          const request = objectStore.add({ key, value });

          request.onerror = reject;
          request.onsuccess = resolve;
        }

        return resolve(null);
      };
    });
  }

  getData(key, _default = null) {
    const request = this.db
      .transaction([this.storeName])
      .objectStore(this.storeName)
      .get(key);

    return new Promise((resolve, reject) => {
      request.onerror = (event) => {
        return reject(event);
      };
      request.onsuccess = (event) => {
        if (event.target.result && event.target.result.value) {
          return resolve(event.target.result.value);
        }
        return resolve(_default);
      };
    });
  }
}
