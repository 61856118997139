import { QuestionnairePage } from "../shared/questionnaire";
import { html, nothing } from "lit";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { ref, createRef } from "lit/directives/ref.js";
import {msg, str} from '@lit/localize';

export class PageBrainCheck extends QuestionnairePage {
  #startButtonRef = createRef();

  get categoryName() {
    return msg("BrainCheck");
  }

  get hasIntro() {
    return true;
  }

  get hasRewards() {
    return true;
  }

  get rewards() {
    return {
      25: {
        title: msg(str`You have completed ${25} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`Keep it up. You’ve earned ${25} points already!`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 25,
      },
      50: {
        title: msg(str`You have completed ${50} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`Keep it up. You’ve earned ${50} points so far.`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 50,
      },
      100: {
        title: msg(str`You have completed ${100} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`Impressive, you’re still here! This earned you ${100} points! 98% made it up to here. 77% of them said: Qogni impacted my life..`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 100,
      },
      200: {
        title: msg(str`You have completed ${200} questions!`, { desc: 'Title to show reward for completing certain questions in braincheck and healthcheck' }),
        text: msg(str`You can do it. This earned you ${200} points so far! 94% made it up to here. 52% uses the Qogni app on a daily basis.`, { desc: 'Description to show reward for completing certain questions in braincheck and healthcheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 200,
      },
      275: {
        title: msg(str`You have completed ${275} questions! Almost there!`, { desc: 'Title to show reward for completing 275 questions in braincheck and healthcheck' }),
        text: msg(str`You are in the final stretch. You Rock. Did you know that out of the 92% that completed the BrainCheck, 42% indicated that they are inspired to make a change`, { desc: 'Description to show reward for completing certain questions in braincheck'}),
        image: "/assets/img/balance.svg",
        rewardAmount: 275,
      },
    };
  }

  get demoQuestions() {
    return [
      {
        name: "I feel generally great",
        description: "Description of the question",
      },
    ];
  }

  async connectedCallback() {
    await super.connectedCallback();
    Task.run(
      async () => {
        await this.initPromise;
        this.loading = false;
      },
      {
        ghost: this.#startButtonRef.value,
      }
    );
  }

  renderTourIntro() {
    return html`
      <section class="hero">
        <h1>${msg(`Let us explain!`, {desc: 'heading for Intro tour for braincheck'})}</h1>

        <p>
          ${msg(str`In the next screens we'll highlight the key features of this test. Or, if you prefer, you can skip ahead and start the ${this.categoryName} right away.`, {desc: 'description for intro tour for braincheck'})}
        </p>
      </section>

      <p class="center">
        <img
          src="/assets/img/explanation.svg"
          alt="explaining boy"
          class="fluid"
          style="max-height: 400px;"
          loading="lazy"
        />
      </p>

      <button type="button" class="wide" @click=${this.nextStep}>
        ${msg("Start introduction", {desc: 'button label to start braincheck'})}
      </button>
      <button
        type="button"
        class="wide simple mt-small"
        @click=${this.skipIntro}
      >
        ${msg("Skip the introduction", {desc: 'button label to skip braincheck introduction'})}
      </button>
    `;
  }

  renderIntro() {
    return html`
      <section class="hero">
        <p>${msg(`Your Brain Performance: “Discover, Assess, Improve”`, { desc: 'Used for sub heading to braincheck' })}</p>
      </section>

      <section class="card">
        <accordion-list>
          <details class="red" open>
            <summary>${msg("What is the BrainCheck?", {desc: "Title for explaining what is braincheck in the Braincheck page"})}</summary>
            ${msg(`The BrainCheck is a comprehensive assessment tool designed to evaluate your cognitive functions and mental health. It analyzes neurotransmitter levels and cognitive abilities to help tailor personalized health strategies on the Qogni platform.`, {desc: "description for What is the BrainCheck?"})}
          </details>
          <details class="yellow">
            <summary>${msg("How long does it take?", {desc: "Title for explaining how long questionnaire"})}</summary>
            ${msg(`On average, the BrainCheck takes about 20 minutes to complete. Pause and resume anytime; your progress is saved.`, {desc: "description for How long does braincheck take? in the Braincheck page"})}
          </details>
          <details class="green">
            <summary>${msg("Can I skip questions?", {desc: "Title for explaining can I skip questions?"})}</summary>
            ${msg(str`While it's important to answer all questions to ensure the accuracy of your assessment, please note that individual answers are not saved, preserving your privacy.`, {desc: 'description for Can I skip questions?'})}
          </details>
          <details class="blue">
            <summary>${msg(`How important is the test?`, {desc: 'Title for explaining how important is the test?'})}</summary>
            ${msg(`The BrainCheck is essential as it forms the foundation of your personalized health plan on Qogni, aiming to enhance cognitive performance and overall mental wellbeing.`, {desc: 'description for `How important is the test? in the Braincheck page'})}
          </details>
        </accordion-list>

        ${this.questionnaire
          ? html` <p>${msg(str`Resume your unfinished ${this.categoryName}.`)}</p> `
          : nothing}
        ${this.canStartNew !== false
          ? html`
              <button
                type="button"
                class="wide"
                ?disabled=${this.loading}
                ${ref(this.#startButtonRef)}
                @click=${this.startButtonClick}
              >
                ${this.questionnaire
                  ? html`${msg(str`Continue the ${this.categoryName}`, { desc: 'Button label to continue questionnaire (Healthcheck or Braincheck)' })}`
                  : html`${msg(str`Start the ${this.categoryName}`, {desc: 'button label for start questionnaire (Healthcheck or Braincheck)'})}`}
              </button>
            `
          : html`
              <p>${msg(str`You recently finished a ${this.categoryName}.`, {desc: 'short description explaining user finished questionnaire (Healthcheck or Braincheck)'})}</p>
              <a href="/results" class="button wide small">
                ${msg(str`Show results of last ${this.categoryName}`, {desc: 'button label to navigate result page for finished questionnaire (Healthcheck or Braincheck)'})}
              </a>
            `}
        ${this.hasFinishedBefore && this.canStartNew !== false
          ? html`
              <section class="card mt-tiny">
                <p>
                  ${msg(str`You previously finished another ${this.categoryName}. You can see your results below.`,
                    { desc: 'Message informing the user about their recently completed activity and providing a link to results.' })}
                </p>
                <a href="/results" class="button outline wide small">
                  ${msg(str`Show results of last ${this.categoryName}`,
                    {desc: 'button label to navigate result page for finished questionnaire (Healthcheck or Braincheck)'}
                  )}
                </a>
              </section>
            `
          : nothing}
      </section>
    `;
  }
}
