import "@lit-labs/virtualizer";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { WorkoutDomain, workoutDurations } from "../../domain/workout-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PullToRefreshMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import "./single-workout";
import "./workout-card";
import {msg, str} from '@lit/localize';

const BaseClass = PullToRefreshMixin(
  OnboardedMixin(AuthenticatedMixin(WidgetEnabledPWAPage))
);

export class PageWorkouts extends BaseClass {
  #domain;
  #page = 1;
  #lastPage;
  #filter = "";
  #duration;
  #category;
  #pagination;

  #fullList = [];

  constructor() {
    super();
    this.#domain = new WorkoutDomain();
    this.onHashChangeBound = this.onHashChange.bind(this);
    this._computedWorkoutList = [];
  }

  static get properties() {
    return {
      ...super.properties,
      workoutCategories: { type: Array },
      workoutId: { type: Number },
      workoutList: { type: Array },
      _computedWorkoutList: { type: Array },
    };
  }

  async connectedCallback() {
    await super.connectedCallback();
    this.#getWorkoutsList();
    if (!this.widget) {
      this.#getWorkoutsCategories();

      this.addEventListener("refresh", this.#refreshList);

      if (window.location.hash) {
        this.workoutId = window.location.hash.substring(1);
      }

      window.addEventListener("hashchange", this.onHashChangeBound);
    }
  }

  async disconnectedCallback() {
    await super.disconnectedCallback();
    this.removeEventListener("refresh", this.#refreshList);
    window.removeEventListener("hashchange", this.onHashChangeBound);
  }

  willUpdate(changeProps) {
    if (changeProps.has("workoutList")) {
      this.#fullList = [...this.#fullList, ...this.workoutList];
      const numOfWorkouts = this.#fullList.length;
      const { total } = this.#pagination;
      const numOfShimmer = total - numOfWorkouts;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedWorkoutList = [...this.#fullList, ...shimmerList];
    }
  }

  onHashChange() {
    this.workoutId = window.location.hash.substring(1);
  }

  get #appliedFilters() {
    return {
      page: this.#page,
      filter: this.#filter,
      duration_max: this.#duration,
      category: this.#category,
    };
  }

  async #getWorkoutsList() {
    const task = async () => {
      try {
        const options = this.#appliedFilters;
        if (this.widget) {
          options["per_page"] = 1;
          options["sort"] = "random_daily";
        }

        const response = await this.#domain.getWorkoutsList(options);
        if (!response) return;
        this.workoutList = response?.data;
        this.#lastPage = response?.pagination?.last_page;
        this.#pagination = response?.pagination;
      } catch (err) {
        app.addToastMessage(`Workouts: ${err}`, { type: "error" });
        throw err;
      }
    };

    await Task.run(task);
  }

  async #getWorkoutsCategories() {
    const task = async () => {
      try {
        const response = await this.#domain.getWorkoutCategories();
        this.workoutCategories = response?.data;
      } catch (err) {
        app.addToastMessage(`Workout Categories: ${err}`, { type: "error" });
        throw err;
      }
    };

    await Task.run(task);
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      priority: 800,
      title: msg("Workout of the Day", { desc: "This is a heading or title displayed on a action center page that highlights a widget featured workout for the day." }),
      cta: {
        href: "/workouts",
      },
    };
  }

  renderWidget() {
    if (this.workoutList === undefined)
      return html`
        <app-shimmer
          style="height: 200px; width: 100%; margin-bottom: 0;"
        ></app-shimmer>
      `;
    const workout = this.workoutList.pop();
    if (!workout)
      return html`<section class="card"><h3>${msg("No workout found!", { desc: "label when no workout found for workout of the day" })}</h3></section>`;
    const image = workout.thumbnail_urls
      .filter((e) => e.indexOf(".webp") !== -1)
      .pop();

    return html`
      <a href="/workouts#${workout.id}">
        <section
          class="card background-image"
          style="--img: url(${image ?? "/assets/img/ico-512.png"})"
        >
          <h3>${workout.name}</h3>
        </section>
      </a>
    `;
  }

  renderPage() {
    return html`
      ${this.workoutId
        ? html`<master-detail
            ><page-single-workout
              .workoutId=${this.workoutId}
            ></page-single-workout
          ></master-detail>`
        : nothing}

      <workout-search>
        <x-form>
          <form>
            <label>
              <span data-label>${msg("Search for workouts", { desc: "Label for a text field used to search for workouts" })}</span>
              <input
                type="search"
                placeholder=${msg("Search for workouts", { desc: "Label for a text field used to search for workouts" })}
                @input="${throttle(this.#onSearchChange.bind(this), 500)}"
              />
            </label>
          </form>
        </x-form>
        <flex-container breakpoint="tiny">
          <flex-item class="col-6">
            <filter-options
              id="categories"
              title=${msg("Categories")}
              type="radio"
              .options=${this.workoutCategories || []}
              icon="ul"
              valueExpression="id"
              labelExpression="name"
              @change="${this.#onCategoriesChange}"
            >
            </filter-options>
          </flex-item>
          <flex-item class="col-6">
            <filter-options
              id="duration"
              title=${msg("Duration")}
              type="radio"
              .options=${workoutDurations}
              icon="clock"
              @change="${this.#onDurationChange}"
            >
            </filter-options>
          </flex-item>
        </flex-container>
      </workout-search>

      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (this._computedWorkoutList.length === 0 && this.#pagination && this.#pagination.total === 0) {
      return html`
        <div class="empty-state">
          <h2>${msg(str`No ${msg("Workouts")} Found`, { desc: "Displayed when no records are found based on the user's search or filter criteria." })}</h2>
          <p>
            ${msg(str`We couldn't find any ${msg("Workouts")} that match your search. Try changing your filters, using different keywords.`, { desc: "Displayed when no record match the user's search or filters. Suggests trying different keywords or adjusting filters." })}
          </p>
        </div>
      `
    }

    if (this._computedWorkoutList.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="workout"></general-shimmer>`
        )}
      `;
    }

    return html`
      <lit-virtualizer
        .items=${this._computedWorkoutList}
        .renderItem=${this.#renderWorkoutListItem}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderWorkoutListItem(workout) {
    if (!workout)
      return html`<general-shimmer type="workout"></general-shimmer>`;
    return html` <workout-card .workout=${workout}> </workout-card> `;
  }

  #resetList() {
    this.#fullList = [];
    this._computedWorkoutList = [];
    this.#page = 1;
  }

  async #onSearchChange(event) {
    this.#page = 1;
    this.#filter = event.target.value || "";
    this.#resetList();
    await this.#getWorkoutsList();
  }

  async #onCategoriesChange(e) {
    this.#page = 1;
    this.#category = e.target.value || "";
    await this.#getWorkoutsList();
  }

  async #onDurationChange(e) {
    this.#page = 1;
    this.#duration = e.target.value || "";
    await this.#getWorkoutsList();
  }

  async #onScrollEnd() {
    this.#page++;
    if (this.#page === 1 || this.#page <= this.#lastPage) {
      await this.#getWorkoutsList();
    }
  }

  async #refreshList() {
    this.#page = 1;
    await this.#getWorkoutsCategories();
    await this.#getWorkoutsList();
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#fullList.length) {
      await this.#onScrollEnd();
    }
  }
}
