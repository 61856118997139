import { FlowPage, UI as baseUI, selectOne } from "../shared/flow-page";
import { msg } from "@lit/localize";
const UI = {
  ...baseUI,
  signoutConfirm: {
    renderInput: selectOne,
    items: {
      yes: msg("Yes, sign me out"),
      no: msg("Cancel"),
    },

    transform: {
      out: (value) => {
        return value === "yes";
      },
    },
  },
};

export class SignOutPage extends FlowPage {
  async flowStart(wf) {
    const doSignout = await wf.ask(
      msg("You're about to sign out of Qogni."),
      UI.signoutConfirm
    );

    if (doSignout) app.signOut();
    else history.back();
  }
}
