import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import { config } from "../../../qogni-app-config";
import { AccountDomain } from "../../../domain/account-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { msg, str } from "@lit/localize";

export class PageShareBenefit extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;

  constructor() {
    super();
    this.#domain = new AccountDomain();
    this.query = new URLSearchParams();
  }

  render() {
    let inviteName = new URLSearchParams(location.search).get("invite") ?? null;
    if (inviteName) inviteName = atob(inviteName);

    return html`
      <section class="card red">
        ${app.config.routes["/benefits"].run.renderShareIntro()}
      </section>

      <section class="card">
        <h3>${msg(str`Invite ${this.getInviteName(inviteName)} to Qogni!`, { desc: "Share and benifits page heading, prompting the user to invite someone to Qogni." })}</h3>

        <x-form
          id="invite-form"
          dirty-check
          live
          unsanitized
          @action=${this.share.bind(this)}
        >
          <form method="post" action="" class="material">
            <fieldset>
              <textarea
                rows="6"
                data-label=${msg("Personal Invitation", { desc: "Label for a textarea to sending a personal invitation." })}
                name="invite"
                required
                placeholder=${msg("Enter your personal invitation", { desc: "Textarea placeholder text for the user to enter their personal invitation message." })}
              >${msg(str`Hey`)} ${inviteName ?? ""},
${msg(`I've started using the Qogni App. Check it out! I want to challenge you and Boost our Brains together!`, { desc: "Textarea initial message to share excitement about using the Qogni App and inviting others to join the challenge." })} 🌟💪
              </textarea>
            </fieldset>
            <fieldset>
              <button type="submit">${msg("Share invitation", { desc: "Button label to share an invitation to others." })}</button>
            </fieldset>
          </form>
        </x-form>
      </section>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.query = new URLSearchParams(location.search);
  }

  getInviteName(name) {
    const params = new URLSearchParams(location.search);
    if (params.has("invite") && name) return name;

    return msg("people");
  }

  async share(e) {
    e.preventDefault();
    const state = e.target.closest("x-form").state;

    Task.run(
      async () => {
        const inviteData = {
          source: "share & benefit",
        };
        if (this.query.has("sourceId"))
          inviteData.source_id = atob(this.query.get("sourceId"));
        if (this.query.has("sourceType"))
          inviteData.source_type = this.query.get("sourceType");

        const data = {
          title: "Come join me at Qogni",
          text: state["x-form-data"].invite || "",
          url: `${config.absoluteUrl}/?${new URLSearchParams({
            invite: btoa(
              await this.#domain.createAdvancedInvitation(inviteData)
            ),
          })}`,
        };

        try {
          await navigator.share(data);
        } catch (ex) {
          console.error("Could not share", ex);
          const shareText = `${data.text}\n${data.url}`;
          await navigator.clipboard.writeText(shareText);
          app.addToastMessage("Invitation copied to your clipboard.");
        }
      },
      {
        ghost: document.documentElement,
      }
    );
  }
}
