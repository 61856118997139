import { msg } from "@lit/localize";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html } from "lit";
import { ChannelDomain } from "../../domain/channel-domain";

export class ChannelListItem extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;

  static get properties() {
    return {
      channel: { type: Object },
    };
  }

  constructor() {
    super();
    this.#domain = new ChannelDomain();
  }

  async #channelFollowToggle(e) {
    e.preventDefault();
    const task = async () => {
      const { id, is_following } = this.channel;
      if (is_following) {
        await this.#domain.unfollow(id);
      } else {
        await this.#domain.follow(id);
      }

      await this.#refresh();
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #detail() {
    const task = async () => {
      const res = await this.#domain.detail(this.channel.id);
      if (res) {
        this.channel = res.data;
      }
    };

    Task.run(task, {
      ghost: this,
      description: "Loading channel details...",
    });
  }

  async #refresh() {
    await this.#detail();
  }

  render() {
    const { is_following } = this.channel;

    return html`
      <a href="/network/channels/${this.channel.slug ?? this.channel.id}">
        <svg-icon icon="channels"></svg-icon>
        <h4>${this.channel.name}</h4>
        <button
          type="button"
          class="button tiny outline align-content-end"
          @click=${this.#channelFollowToggle}
        >
          ${is_following ? msg("Unfollow") : msg("Follow")}
        </button>
      </a>
    `;
  }
}
customElements.define("channel-list-item", ChannelListItem);
