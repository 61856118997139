
export const QOGNI = {
  meta: {

    colors:{
      red: "--color-accent-200",
      yellow: "--color-accent-100",
      black: "--color-primary-300",
      blue: "--color-accent-300",
      green: "--color-accent-400",
      gray: "--color-primary-400"

    },

    languages: {
      "da-DK": "Dansk",
      "de-DE": "Deutsch",
      "en-US": "English",
      "es-ES": "Español",
      "fr-FR": "Français",
      "nl-NL": "Nederlands",
      "nb-NO": "Norsk",
      "pt-PT": "Português",
      "sv-SE": "Svenska",
      "tr-TR": "Türkçe",
    },
  }
}
