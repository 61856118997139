import { msg } from "@lit/localize";
//#region Imports
import { version as packageVersion } from "../package.json";
import { PageAccount } from "./pages/account/account";
import { PageBenefits } from "./pages/benefits/index";
import { PageShareBenefit } from "./pages/benefits/share/index";
import { PageBrainCheck } from "./pages/braincheck";
import { PageNewCircle } from "./pages/network/circles/new/index";
import { PageNetwork } from "./pages/network/network";
import { PageEnter } from "./pages/enter";
import { PageHealthcheck } from "./pages/healthcheck";
import { PageHome } from "./pages/home";
import { PageMessages } from "./pages/messages/messages";
import { PageMessageThread } from "./pages/messages/thread";
import { PageNotifications } from "./pages/notifications";
import { PageOffline } from "./pages/offline";
import { PageProfile } from "./pages/profile/profile";
import { PageFollowing } from "./pages/profile/following";
import { PageProgramActivation } from "./pages/program/activation";
import { PageProgram } from "./pages/program/program";
import { PageRecipes } from "./pages/recipes/recipes";
import { PageSingleRecipe } from "./pages/recipes/single-recipe";
import { PageResults } from "./pages/results/index";
import { PageSettings } from "./pages/settings";
import { PageTimeline } from "./pages/timeline";
import { PageSingleWorkout } from "./pages/workouts/single-workout";
import { PageWorkouts } from "./pages/workouts/workouts";
import { PageWhatsNew } from "./pages/whats-new";
import { PageSingleCircle } from "./pages/network/circles/single-circle";
import { PageSingleChannel } from "./pages/network/channels/single-channel";
import { PageSinglePost } from "./pages/posts/single-post";
import { PageSavedItems } from "./pages/saved-items/index";
import { PageSingleArticle } from "./pages/articles/single-article";
import { PageRating } from "./pages/rating/index";
import { PageChannelsList } from "./pages/network/channels/index";
import { OnboardingFlowPage } from "./pages/onboarding-v2";
import { PagePerformance } from "./pages/results/performance";
import { PagePersonality } from "./pages/results/personality";
import { PagePotential } from "./pages/results/potential";
import { PageTopics } from "./pages/results/topics";
import { PageGroupsList } from "./pages/network/groups/index";
import { PageSingleGroup } from "./pages/network/groups/single-group";
import { PageNewGroup } from "./pages/network/groups/new-group";
import { PagePrograms } from "./pages/program/programs";
import { PageFeedback } from "./pages/feedback";
import { PageAIBuddy } from "./pages/ai-buddy";
import { PageESS } from "./pages/ess";
import { SignOutPage } from "./pages/sign-out";
import { PageEPME } from "./pages/e-pme";
//#endregion

// eslint-disable-next-line no-undef
const env = require("env");
window.env = env;

export const version = env.VERSION ?? packageVersion;

export const config = {
  qoachEmail: "qoach@qogni.com",
  baseUrl: env.API_ROOT,
  parentApiUrl: ["localhost", "127.0.0.1"].includes(location.hostname)
    ? "https://dev.qogni.io/api"
    : env.API_ROOT.replace("/v1.0", ""),
  absoluteUrl: env.PWA_URL,
  environment: env.ENVIRONMENT,
  enableTranslations: true,
  serviceWorker: {
    enabled: !!env.SW_ENABLED,
  },
  tosUrl: "https://qogni.com/legal/",
  privacyPolicyUrl: "https://qogni.com/legal/",

  routes: {
    "/": {
      run: PageHome,
      name: "Action Centre",
      translatedName: () =>
        msg("Action Centre", { desc: "Used in route config and sidebar" }),
      config: {
        icon: "home",
        description: "Your main dashboard",
        primary: true,
        color: "black",
        menu: {
          side: {
            index: 10,
            featured: true,
          },
        },
      },
    },
    "/account": {
      run: PageAccount,
      config: {
        description: "Your Qogni Account settings",
        tags: ["email", "allergies", "diet"],
        icon: "account",
        color: "yellow",
        menu: {
          side: {
            group: "settings",
            index: 0,
          },
        },
      },
    },
    "/benefits": {
      run: PageBenefits,
      config: {
        description: "Information about the Qogni Point System",
        icon: "diamond",
        tags: ["earn", "share", "grow"],
        menu: {
          side: {
            group: "other",
          },
        },
      },
      routes: {
        "/share": {
          run: PageShareBenefit,
        },
      },
    },
    "/braincheck": {
      run: PageBrainCheck,
      name: "BrainCheck",
      translatedName: () => msg("BrainCheck"),
      config: {
        group: "tools",
        description: "Brain survey",
        icon: "brain",
        color: "yellow",
        menu: {
          side: {
            index: 20,
            group: "tools",
          },
        },
      },
    },
    "/network": {
      run: PageNetwork,
      name: "Network",
      translatedName: () =>
        msg("Network", { desc: "Used in route config and sidebar" }),
      config: {
        icon: "people",
        color: "green",
        description:
          "Connect with people, create circles, follow channels, etc.",
        menu: {
          bottom: {
            index: 4,
          },
          side: {
            index: 50,
            group: "social",
            featured: true,
          },
        },
      },
      routes: {
        "/circles": {
          routes: {
            "/new": {
              run: PageNewCircle,
            },
            "/:id": {
              run: PageSingleCircle,
            }, //circles/single-circle
          },
        },
        "/channels": {
          run: PageChannelsList,
          routes: {
            "/:id": {
              run: PageSingleChannel,
            }, //channels/single-circle
          },
        },
        "/groups": {
          run: PageGroupsList,
          routes: {
            "/:id": {
              name: "Create/Edit Group page",
              run: PageNewGroup,
              routes: {
                "/view": {
                  name: "Group Detail Page",
                  run: PageSingleGroup,
                },
              },
            },
          },
        },
      },
    }, // connections-navigation
    "/enter": {
      run: PageEnter,
    },
    "/healthcheck": {
      run: PageHealthcheck,
      name: "HealthCheck",
      translatedName: () => msg("HealthCheck"),
      config: {
        icon: "heart",
        color: "red",
        description: "Health Check Survey",
        menu: {
          side: {
            index: 30,
            group: "tools",
          },
        },
      },
    },
    "/ess": {
      run: PageESS,
      name: "ESS",
      translatedName: () => msg("ESS", {desc: 'ESS - Employee Satisfaction Survey, Dutch: MTO - Medewerkertevredenheidsonderzoek. Please don\'t translate it unless you have the official translation and the abbreviation of the country.'}),
      config: {
        icon: "compass",
        color: "green",
        //company: true,
        menu: {
          side: {
            group: "tools",
            index: 10,
          },
        },
      },
    },
    "/e-pmo": {
      run: PageEPME,
      name: "ePMO",
      title: "ePMO",
      config: {
        icon: "compass",
        color: "yellow",
        //company: true,
        menu: {
          side: {
            group: "tools",
            index: 11,
          },
        },
      },
    },

    "/sign-out": {
      run: SignOutPage,
    },
    "/notifications": {
      run: PageNotifications,
      config: {
        icon: "bell",
        color: "red",
        description: "See your latest notifications",
        menu: {
          side: {
            index: 0,
            group: "settings",
          },
          top: {
            index: 1,
            unreadStateKey: "unread_notifications",
          },
        },
      },
    }, // account/notifications
    "/messages": {
      run: PageMessages,
      config: {
        icon: "message",
        color: "blue",
        description: "Chat with people",
        menu: {
          side: {
            index: 0,
            group: "settings",
          },
          top: {
            index: 1,
            unreadStateKey: "unread_messages",
          },
        },
      },
      routes: {
        "/:conversationId": {
          tagName: "page-single-thread",
          run: PageMessageThread,
        },
      },
    },
    "/ai-buddy": {
      run: PageAIBuddy,
      name: "AI Buddy",
      config: {
        description: "Qogni AI Buddy",
        text: "AI",
        color: "yellow",
        menu: {
          side: {
            index: 11,
            group: "tools",
            featured: true,
          },
        },
      },
    },
    "/settings": {
      run: PageSettings,
      config: {
        description: "Configure App Settings",
        icon: "settings",
        color: "black"

      },
    },
    "/offline": {
      run: PageOffline,
    },
    "/profile": {
      run: PageProfile,
      routes: {
        "/:selector": {},
        "/:selector/followers": {
          run: PageFollowing,
        },
      },
    },
    "/programs": {
      run: PagePrograms,
      routes: {
        "/#:programId": {
          //tagName: "page-single-program",
          //run: PageSingleProgram,
        },
      },
      config: {
        icon: "ul",
        color: "black",
        menu: {
          side: {
            index: 1,
            _group: "solutions",
          },
        },
      },
    },
    "/program": {
      run: PageProgram,
      name: "Program",
      translatedName: () =>
        msg("Program", { desc: "Used in route config and sidebar" }),
      routes: {
        "/activation": {
          run: PageProgramActivation,
        },
      },
      config: {
        description:
          "The Qogni Program you're running after taking the Brain- and the Healthcheck",
        icon: "tasks",
        tags: ["buy", "pay", "shop", "points"],
        menu: {
          bottom: {
            index: 60,
          },
          side: {
            index: 1,
            group: "solutions",
          },
        },
      },
    },

    "/onboarding": {
      run: OnboardingFlowPage,
      routes: {
        "/*": {},
      },
    },
    "/feedback": {
      run: PageFeedback,
    },
    "/recipes": {
      run: PageRecipes,
      name: "Recipes",
      translatedName: () => msg("Recipes"),
      config: {
        description:
          "Find and prepare the healthiest (but most delicious 😉) food",
        icon: "food",
        color: "yellow",
        menu: {
          side: {
            index: 70,
            group: "solutions",
          },
        },
      },
      routes: {
        "/#:recipeId": {
          tagName: "page-single-recipe",
          run: PageSingleRecipe,
        },
      },
    },

    "/results": {
      run: PageResults,
      name: "Results",
      translatedName: () =>
        msg("Results", { desc: "Used in route config and sidebar" }),
      config: {
        icon: "metrics",
        color: "blue",
        description: "Your survey Results and Insights",
        menu: {
          side: {
            index: 90,
            group: "tools",
          },
        },
      },
      routes: {
        "/performance": {
          run: PagePerformance,
        },
        "/personality": {
          run: PagePersonality,
        },
        "/potential": {
          run: PagePotential,
        },
        "/topics": {
          run: PageTopics,
        },
      },
    },
    "/timeline": {
      run: PageTimeline,
      name: "Timeline",
      translatedName: () => msg("Timeline"),
      config: {
        icon: "timeline",
        color: "blue",
        description:
          "Your personal timeline, especially delivered to you only!",
        menu: {
          bottom: {
            index: 2,
          },

          side: {
            index: 40,
            group: "social",
            featured: true,
          },
        },
      },
    },
    "/whats-new": {
      run: PageWhatsNew,
      config: {
        icon: "info",
        color: "blue",
        menu: {
          side: {
            group: "other",
          },
        },
      },
    },
    "/workouts": {
      run: PageWorkouts,
      name: "Workouts",
      translatedName: () => msg("Workouts"),
      config: {
        icon: "workout",
        color: "red",
        menu: {
          side: {
            index: 80,
            group: "solutions",
          },
        },
      },
      routes: {
        "/#:workoutId": {
          tagName: "page-single-workout",
          run: PageSingleWorkout,
        },
      },
    },
    "/posts": {
      routes: {
        "/:id": {
          tagName: "page-single-post",
          run: PageSinglePost,
        },
      },
    },
    "/articles": {
      routes: {
        "/:id": {
          tagName: "page-single-article",
          run: PageSingleArticle,
        },
      },
    },
    "/saved-items": {
      tagName: "page-saved-items",
      run: PageSavedItems,
      name: "Saved items",
      config: {
        icon: "save",
        color: "black",

      },
    },
    "/rating/:type/:ratingId": {
      tagName: "page-rating",
      run: PageRating,
    },
  },
};
