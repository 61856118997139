import "@lit-labs/virtualizer";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { ChannelDomain } from "../../../domain/channel-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import { msg } from "@lit/localize";

export class PageChannelsList extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #page = 1;
  #lastPage;
  #pagination;

  #fullList = [];

  static get properties() {
    return {
      list: { type: Array },
      _computedList: { type: Array },
    };
  }

  constructor() {
    super();
    this.#domain = new ChannelDomain();
    this._computedList = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.#getList();
  }

  willUpdate(changeProps) {
    if (changeProps.has("list")) {
      this.#fullList = [...this.#fullList, ...this.list];
      const numOfChannels = this.#fullList.length;
      const { total } = this.#pagination;
      const numOfShimmer = total - numOfChannels;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedList = [...this.#fullList, ...shimmerList];
    }
  }

  async #getList() {
    return Task.run(async () => {
      const res = await this.#domain.list();
      if (!res.status) return;

      this.list = res?.data;
      this.#lastPage = res?.pagination?.last_page;
      this.#pagination = res?.pagination;
    });
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#page * 25) {
      this.#page++;
      if (this.#page === 1 || this.#page <= this.#lastPage) {
        await this.#getList();
      }
    }
  }

  render() {
    return html`
      <section class="hero center">
        <h1>${msg("Channels")}</h1>
      </section>

      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (this._computedList.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="card"></general-shimmer>`
        )}
      `;
    }
    return html`
      <lit-virtualizer
        .items=${this._computedList}
        .renderItem=${this.#renderListItem.bind(this)}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderListItem(channel) {
    if (!channel)
      return html`<general-shimmer type="card"></general-shimmer>`;
    return html`
      <channel-list-item .channel=${channel}></channel-list-item>
    `;
  }
}
