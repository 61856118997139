import { RichEditor } from "@qogni-technologies/design-system/src/components/base/rich-editor";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";
import { TimelineDomain } from "../../domain/timeline-domain";
import { config } from "../../qogni-app-config";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PostRichEditor extends RichEditor {
  #domain;

  constructor() {
    super();

    this.#domain = new TimelineDomain();
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener(
      "refstart",
      throttle(this.#onRefStart.bind(this), 200)
    );
  }

  async #onRefStart(e) {
    
    const reference = e.detail;
    const search = reference.search;

    const task = async () => {
      const res = await this.#domain.searchUsers(search);

      this.references = res.filter(i => i.firstname && i.lastname).slice(0, 5).map((i) => {
        return {
          text: i.firstname + " " + i.lastname,
          type: reference.type,
          image: i.profile_img_url,
          url: config.absoluteUrl + "/profile/" + i.id,
        };
      });

    };

    await Task.run(task, {global: false});
  }
}

customElements.define("post-rich-editor", PostRichEditor);
