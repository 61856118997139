import { FlowPage, UI as baseUI } from "../shared/flow-page";

const UI = {
  ...baseUI,
};

export class PageEPME extends FlowPage {
  async flowStart(wf) {
    await this.showHtml(wf, "Coming soon!", "Electronic Preventive Medical Examination");

    this.closeFlow();
  }
}
